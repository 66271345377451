import React from "react";
import {
  TiSocialFacebook,
  TiSocialTwitter,
  TiSocialPinterest,
} from "react-icons/ti";

const Footer = () => {
  return (
    <footer className="bg-orange-500 w-full sm:h-48 font-bold mt-10 text-white md:p-6  p-10 mt-32">
      Suivez nous sur les réseaux sociaux
      <div className="flex text-4xl justify-center sm:mt-4">
        <a
          href="https://www.facebook.com/alaffiche.ch"
          target="_blank"
          rel="noreferrer"
        >
          <TiSocialFacebook className="mx-2 cursor-pointer" />
        </a>
        
        <a
          href="https://www.pinterest.com/alaffichech"
          target="_blank"
          rel="noreferrer"
        >
          <TiSocialPinterest className="mx-2 cursor-pointer" />
        </a>
      </div>
      <p className="md:flex justify-center mt-7 font-semibold text-xs md:text-sm">
        Copyright © 2022 |
        <a
          className="mx-2"
          href="https://alaffiche.ch"
          target="_blank"
          rel="noreferrer"
        >
          www.alaffiche.ch
        </a>
        | All right reserved | Created by:
        <a
          className="mx-2"
          href="https://rund3v.com"
          target="_blank"
          rel="noreferrer"
        >
        Rund3v
        </a>
      </p>
      <p className="md:flex justify-center font-semibold text-xs md:text-sm">
        Partenaires
        <a
          className="mx-2"
          href="https://swissromande.ch"
          target="_blank"
          rel="noreferrer"
        >
          www.swissromande.ch
        </a>
        |
        <a className="mx-2" href="https://rund3v.com" target="_blank" rel="noreferrer">
          www.rund3v.com
        </a>
      </p>
    </footer>
  );
};

export default Footer;
