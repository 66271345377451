import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Hamburger  from "hamburger-react";

const Nav = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [desktop, setDesktop] = useState();

  const displayMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  // Check if on mobile or on desktop
  useEffect(() => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      // The viewport is less than 768 pixels wide
      console.log("This is a mobile device.");
      setDesktop(false);
    } else {
      setDesktop(true);
    }
  }, []);

  return (
    <nav className="justify-around mt-6 mb-5 md:mb-0 lg:mr-4 md:flex md:mt-0 ">
      <div className="absolute top-3 right-4 md:hidden text-3xl cursor-pointer text-gray-700 z-50">
        <Hamburger onToggle={displayMobileMenu} toggled={mobileMenu} direction="right" size={25}/>
      </div>
      <ul
        className={
          desktop
            ? "md:flex "
            : `fixed h-full w-full bg-white  z-50 ease-in-out duration-300 ${
                mobileMenu ? "top-16" : "top-full"
              }` // Animation open close menu mobile
        }
        // `top-20 right-0 w-[100vw] bg-white  p-10 pl-15 text-orange-600 fixed h-full z-40  ease-in-out duration-300 ${
        //   !desktop ? "translate-x-0 " : "translate-x-full"
        // }`
      >
        <Link to="/events">
          <li
            className="text-left p-6 border-b  hover:bg-orange-400 hover:text-white lg:p-8 text-gray-600 md:hover:text-orange-400 cursor-pointer md:hover:bg-white md:border-none"
            onClick={() => setMobileMenu(false)}
          >
            Evènements
          </li>
        </Link>

        <Link to="/search">
          <li
            className="text-left p-6 border-b  hover:bg-orange-400 hover:text-white lg:p-8 text-gray-600 md:hover:text-orange-400 cursor-pointer md:hover:bg-white md:border-none"
            onClick={() => setMobileMenu(false)}
          >
            Recherche
          </li>
        </Link>
        <Link to="/archive">
          <li
            className="text-left p-6 border-b  hover:bg-orange-400 hover:text-white lg:p-8 text-gray-600 md:hover:text-orange-400 cursor-pointer md:hover:bg-white md:border-none"
            onClick={() => setMobileMenu(false)}
          >
            Archives
          </li>
        </Link>
        <Link to="/about">
          <li
            className="text-left p-6   hover:bg-orange-400 hover:text-white lg:p-8 text-gray-600 md:hover:text-orange-400 cursor-pointer md:hover:bg-white md:border-none"
            onClick={() => setMobileMenu(false)}
          >
            A propos
          </li>
        </Link>
        <Link to="/add">
          <button
            className={
              !desktop
                ? `fixed bottom-[-20]  block w-full p-4 text-white hover:bg-orange-400 bg-orange-500 z-50 ease-in-out duration-300 ${
                    mobileMenu ? "bottom-0" : "bottom-[-20]"
                  } `
                : "  w-10/12  text-white hover:bg-orange-400 bg-orange-500 rounded-full  md:block md:m-4 md:p-1 md:w-24 lg:w-40 lg:px-10 lg:m-6 lg:p-2   "
            }
            onClick={() => setMobileMenu(false)}
          >
            Publier
          </button>
        </Link>
      </ul>
    </nav>
  );
};

export default Nav;
