import React from "react";

const Map = ({ position }) => {
  const mapUrl = `https://google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&q=${position.lat},${position.lng}&zoom=18`;
  return (
    <iframe
      title="Event location"
      className="rounded-lg w-full h-64"
      src={mapUrl}
      referrerPolicy="no-referrer-when-downgrade"
      allowFullScreen
    ></iframe>
  );
};

export default Map;
