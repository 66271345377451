import axios from "axios";

let url =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5001/api"
    : "https://alaffiche.ch/api";

    // console.warn(url);

export default axios.create({
  baseURL: url,
});
