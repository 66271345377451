import React from "react";
import Events from "./Events";
import SearchBar from "./SearchBar";
import { useState } from "react";
import Container from "./UI/Container";

const Search = ({ listEvents }) => {

  const [listSearch, setListSearch] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [searchStartDate, setSearchStartDate] = useState("");
 // console.log("🚀 ~ file: Search.jsx ~ line 13 ~ Search ~ searchStartDate", searchStartDate)
  const [searchEndDate, setSearchEndDate] = useState("");
  //console.log("🚀 ~ file: Search.jsx ~ line 15 ~ Search ~ searchEndDate", searchEndDate)
  const [resultSearch, setResultSearch] = useState(false);

  const handleSearch = (term) => {
    //console.log("Term to search is : ", term);
    setSearchTerm(term);
    const searchResult = listEvents.filter((event) => {
      return Object.values(event).join("").toLowerCase().includes(term) && !event.archived;
    });
    if (searchResult.length > 0) {
      setListSearch(searchResult);
      setResultSearch(true);
    } else {
      setListSearch([]);
      setResultSearch(false);
    }
  };

  const handleSearchLocation = (location) => {
    //console.log("Location to search is : ", location);
    setSearchLocation(location);
    const searchResult = listEvents.filter((event) => {
      return event.location === location && !event.archived;
    });
    //console.log("searchResult => ", searchResult);
    if (searchResult.length > 0) {
      setListSearch(searchResult);
      setResultSearch(true);
      //console.log("resultSearch => ", resultSearch);
      //console.log("listSearch => ", listSearch);
    } else {
      setListSearch([]);
      setResultSearch(false);
    }
  };

  const handleSearchDate = (start, end) => {
    const selectedStartDate = new Date(start).getTime();
    const selectedEndDate = new Date(end).getTime();
    setSearchStartDate(selectedStartDate);
    setSearchEndDate(selectedEndDate);


    const result = listEvents.filter((event)=>{
      const eventStartDate = new Date(event.startdate).getTime();
      const eventEndDate = new Date(event.enddate).getTime();
      return (eventStartDate >= selectedStartDate && eventEndDate <= selectedEndDate && !event.archived);
    })
    //console.log("🚀 ~ file: Search.jsx ~ line 55 ~ result ~ result", result);

    if(result.length > 0){
      setResultSearch(true);
      setListSearch(result);
    } else {
      setListSearch([]);
      setResultSearch(false);
    }
  


  }

  return (
    <>
      <SearchBar
        handleSearch={handleSearch}
        handleSearchLocation={handleSearchLocation}
        handleSearchDate={handleSearchDate}
        listEvents={listEvents}
      />
      <Container>
        {!searchTerm && !searchLocation && !searchStartDate && !searchEndDate ? (
          <Events listEvents={listEvents} />
        ) : resultSearch ? (
          <Events listEvents={listSearch} />
        ) : (
          <h1 className="text-2xl p-12"> No Result Founded</h1>
        )}
      </Container>
    </>
  );
};

export default Search;
