import React, { useState } from "react";
import { Helmet } from 'react-helmet-async';
import Card from "./UI/Card";
import Container from "./UI/Container";
import Paginate from "./UI/Paginate";

const Archive = ({ listEvents }) => {

const [currentPage, setCurrentPage] = useState(1);
const [eventByPage] = useState(16);

const filteredEvents = listEvents.filter((event) => {
  return event.publish && event.archived;
});

// Pagination logic
const lastIndex = currentPage * eventByPage;
const firstIndex = lastIndex - eventByPage;
const paginateEvents = filteredEvents.slice(firstIndex, lastIndex);

const changePage = (index) => {
  setCurrentPage(index);
  window.scrollTo(0, 0)
  //console.log("setCurrentPge to ", index);
};

  const archive = paginateEvents.map((event) => {
    if (event.publish && event.archived ) {
      return <Card key={event._id} event={event} />;
    }
  });

  return (
    <Container>
      <Helmet>
        <title>Archive & Evénements expirés en Suisse Romande| alaffiche.ch</title>
        <meta
          name="description"
          content="Archive de tous les événements expirés en Suisse Romande sur alaffiche.ch"
        />
      </Helmet>
      <div className="w-full flex justify-around flex-wrap mt-4 min-h-screen">{archive}</div>
      <Paginate
        filteredEvents={filteredEvents.length}
        eventPerPage={eventByPage}
        changePage={changePage}
      />
    </Container>
  );
};

export default Archive;
