import React from "react";
import { Helmet } from "react-helmet-async";
import emailjs, { init } from "@emailjs/browser";
import about from "../images/67873A5E-22CC-41AB-B766-EBB326F449F4_1_105_c.jpg";
import Container from "./UI/Container";
import { useState, useRef, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const About = () => {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [msgSend, setMsgSend] = useState("");
  const [params, setParams] = useState();
  const [loading, setLoading] = useState(false);

  const sendButton = useRef();

  useEffect(() => {
    sendButton.current.disabled = errorMessage || !params ? true : false;
  }, [errorMessage, params]);

  const onChange = (value) => {
    if (name && mail && message) {
      const newParams = {
        name: name,
        mail: mail,
        message: message,
        "g-recaptcha-response": value,
      };
      setParams(newParams);
    } else {
      setErrorMessage("Invalid! Actualiser la plage!");
      setName("");
      setMail("");
      setMessage("");
    }
  };

  //////////////////////////////////////
  //////////// Sending Email //////////
  /////////////////////////////////////

  init("");
  const handleSubmit = (e) => {
    e.preventDefault();

    if (name && mail && message && params) {
      setLoading(true);
      emailjs
        .send(
          process.env.REACT_APP_SERVICE_MAIL,
          process.env.REACT_APP_TEMPLATE_MAIL,
          params,
          process.env.REACT_APP_USER_MAIL
        )
        .then(
          (result) => {
            setName("");
            setMail("");
            setMessage("");
            setErrorMessage("");
            setMsgSend("Votre message a bien été envoyé!");
            setLoading(false);
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      setErrorMessage("Merci de remplir tous les champs !");
    }
  };

  return (
    <Container>
      <Helmet>
        <title>A propos d'alaffiche & Contact | alaffiche.ch</title>
        <meta
          name="description"
          content="A propos du projet alaffiche, histoire et naissance de l'idée "
        />
      </Helmet>
      <div className="container lg:flex col-reverse md:mb-32">
        <section className="lg:w-3/5 w-full">
          <h3 className="mb-8 text-3xl ">A propos</h3>
          <p className="text-justify p-2">
            Propagez vos événements ! Passez du traditionnel panneau d’affichage
            à votre format numérique perso ! Le pilier d’affichage public,
            installé prioritairement sur un lieu de passage, démontre son
            utilité et sa valeur informative, une fois seulement le lecteur
            stoppé en face… Pas très efficace comme moyen de communication à
            l’heure des smartphones et autres objets connectés, n'est-ce pas?
          </p>
          <br />
          <p className="text-justify px-2">
            Consulter les journaux, votre boîte aux lettres, vos mails, ou
            encore le panneau d’affichage public afin de savoir quoi faire le
            week-end prochain ?!.. Ces modes d’expression publique vous semblent
            pas ou peu efficaces, alors découvrez Alaffiche.ch !{" "}
          </p>
          <br />
          <p className="text-justify px-2">
            L'app vous permet de publier ou de consulter tous les événements de
            Suisse romande sur votre natel ou en ligne !!!
          </p>
          <br />
          <p className="text-justify px-2">
            Alaffiche.ch a pour objectif de centraliser toutes les petites et
            grandes manifestations de Suisse romande et de devenir le couteau
            suisse de la communication événementielle de Romandie… Tout en
            mettant en avant les talents des graphistes romands.
          </p>
          <br />
          <p className="text-justify px-2">
            Quelle que soit votre recherche : festival, événement,
            manifestation, foire, activité, théâtre, cinéma, sortie ?
            Alaffiche.ch est l’assurance de ne rater aucun événement à
            proximité, ou alentour…{" "}
          </p>
          <br />

          <p className="text-justify px-2">
            Créé à{" "}
            <a
              className="text-orange-400 hover:text-gray-400"
              target="_blank"
              href="https://www.youtube.com/watch?v=GNJQ-tWcmbA"
            >
              Château-d'Oex
            </a>{" "}
            initialement pour la région du Pays-d’Enhaut. Le site s’est
            rapidement étendu à la Suisse romande…
          </p>
          <br />

          <p className="text-justify px-2">
            Il se veut un moyen moderne et écologique de publier vos affiches et
            événements. Pas d’encre, pas de papier, pas de colle ou scotch, ni
            agrafes ni punaises, le temps des trajets et l’essence consommée
            pour la pose devenue inutiles ! Il est de plus économique ! La
            publication des affiches est gratuite ! Enfin, il vous offre une
            audience sans limites sur le web et les réseaux sociaux,
            contrairement à un nombre limité d’affiches ou de tirages d’un
            journal local…
          </p>
          <br />
          <p className="text-justify px-2">
            Notre concept vous plaît ? Alors, rejoignez-nous sur les réseaux
            sociaux et partagez-le !
          </p>
        </section>
        <section className="lg:w-2/5 p-10 w-full">
          <img className="rounded-full" src={about} alt="About us" />
          <div className="top-6 relative sm:bottom-24 mb-8 mt-3  md:bottom-32 w-full">
            <h2 className="text-2xl mb-3 float-center pb-3">Contactez-nous</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                className="border w-full pl-2"
                placeholder="Nom"
                name="name"
                required
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              <input
                type="email"
                className="border w-full mt-3 pl-2"
                placeholder="Email"
                name="email"
                required
                onChange={(e) => setMail(e.target.value)}
                value={mail}
              />
              <textarea
                name="message"
                id=""
                cols="30"
                rows="5"
                className="border mt-3 w-full pl-2"
                placeholder="Message..."
                required
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              ></textarea>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
                onChange={onChange}
              />
              <p
                className={
                  errorMessage
                    ? "text-orange-600 text-bold text-lg mt-2"
                    : "text-green-600 text-bold text-lg mt-2"
                }
              >
                {errorMessage || msgSend}
              </p>
              <br />
              <button
                ref={sendButton}
                type="submit"
                className="w-full lg:w-1/2 p-2 hover:bg-orange-400 bg-orange-500 text-white float-right mt-3 rounded-sm disabled:opacity-40 disabled:hover:bg-orange-500"
              >
                {loading ? (
                  <span>
                    <svg
                      role="status"
                      className="inline mr-3 w-4 h-4 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Envoyer{" "}
                  </span>
                ) : (
                  <span>Envoyer</span>
                )}
              </button>
            </form>
          </div>
        </section>
      </div>
    </Container>
  );
};

export default About;
