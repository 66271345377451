import React from "react";
import logo from "../logo_a_l_affiche_finale.png";
import Nav from "./UI/Nav";
import { Link } from "react-router-dom";

const Header = ({activateSearch}) => {
  return (
    <div className="md:flex justify-between z-50">
      <Link to="/">
        <img src={logo} alt="logo_a_l_affiche" className="cursor-pointer pt-5 ml-5 h-auto w-40 lg:w-52" />
      </Link>
      <Nav activateSearch={activateSearch}/>
    </div>
  );
};

export default Header;
