import React from "react";
import { motion } from "framer-motion";
import { useState, useEffect  } from "react";

const SearchBar = ({ handleSearch, handleSearchLocation, handleSearchDate, listEvents }) => {
  const [searchTerm, setSearchTerm] = useState("");
  //console.log("searchTerm => ", searchTerm);
  const [searchLocation, setSearchLocation] = useState("");
  //console.log("searchLocation => ", searchLocation);
  const [searchStartDate, setSearchStartDate] = useState("");

  const [searchEndDate, setSearchEndDate] = useState("");


  // Recover search term when coming back on search from single page
  useEffect(() => {
    const term = JSON.parse(localStorage.getItem("searchTerm"));
    if (term) {
      //console.warn("termFromStorage => ", term);
      setSearchTerm(term);
    }
  }, [window.location.pathname]);

  // Recover search location when coming back on search from single page
  useEffect(() => {
    const loc = JSON.parse(localStorage.getItem("searchLocation"));
    if (loc) {
      //console.warn("locationFromStorage => ", loc);
      setSearchLocation(loc);
    }
  }, [window.location.pathname]);

   // Recover search date when coming back on search from single page
   useEffect(() => {
    const startDate = JSON.parse(localStorage.getItem("searchStartDate"));
    const endDate = JSON.parse(localStorage.getItem("searchEndDate"));
    if (startDate || endDate) {
      //console.warn("startDateFromStorage => ", startDate);
      //console.warn("endDateFromStorage => ", endDate);
      setSearchStartDate(startDate);
      setSearchEndDate(endDate);
    }
  }, [window.location.pathname]);

  // HandleSearch Term
  useEffect(() => {
    handleSearch(searchTerm);
    localStorage.setItem("searchTerm", JSON.stringify(searchTerm));
  }, [searchTerm]);

  // HandleSearchLocation
  useEffect(() => {
    handleSearchLocation(searchLocation);
    localStorage.setItem("searchLocation", JSON.stringify(searchLocation));
  }, [searchLocation]);

  // HandleSearchDate
  useEffect(() => {
    handleSearchDate(searchStartDate, searchEndDate);
    localStorage.setItem("searchStartDate", JSON.stringify(searchStartDate));
    localStorage.setItem("searchEndDate", JSON.stringify(searchEndDate));
  }, [searchStartDate, searchEndDate]);

  // Make dynamic drop down cities dynamic
  const data = listEvents.map((event) => {
    if(event.publish && !event.archived && event.location){
       return event.location;
    } else {
      return '';
    }
  });

  // remove duplicate value in the array
  const cities = [...new Set(data)];
  //console.log("🚀 ~ file: SearchBar.jsx ~ line 74 ~ SearchBar ~ cities", cities)

  return (
    <>
    <motion.div
      initial={{ opacity: 0, scale: 1, y: -25 }}
      animate={{ opacity: 1, scale: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="mb-0 mt-0  md:mt-6 lg:mt-0 lg:mb-5  py-5 md:py-3  md:px-20 lg:px-40 xl:px-96 md:flex justify-around md:bg-orange-400 rounded-md sm:rounded-none"
    >
      <input
        type="text"
        name="search"
        className="px-2 my-2 h-8 w-96 mx-auto border md:w-2/12 md:rounded md:mx-3"
        placeholder="Recherche"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
      />
      <select
        value={searchLocation}
        className="pl-1 h-8 w-96 mx-auto md:m-2 border md:w-2/12 md:rounded md:mx-3"
        onChange={(e) => {
          setSearchLocation(e.target.value);
        }}
      >
        <option default value="">
          Ville
        </option>
        {cities.map((city, id) => {
          if(city){
            return (
              <option key={id} value={city}>
                {city}
              </option>
            );
          } else {
            return '';
          }
        
        })}
      </select>

      <input
        type="date"
        className="block h-8 mt-3 border md:m-2 md:w-2/12 w-96 mx-auto md:rounded md:mx-3"
        name="startdate"
        id="startdate"
        onChange={(e) => {
          setSearchStartDate(e.target.value);
        }}
      value={searchStartDate}
      />
      <input
        type="date"
        className="block h-8 mt-3  border md:m-2 md:w-2/12 w-96 mx-auto md:rounded md:mx-3"
        name="enddate"
        id="enddate"
        onChange={(e) => {
          setSearchEndDate(e.target.value);
        }}
        value={searchEndDate}
      />
    </motion.div>
    </>
  );
};

export default SearchBar;
