import React from "react";
import { Helmet } from "react-helmet-async";
import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import emailjs, { init } from "@emailjs/browser";
import Axios from "axios";
import cityList from "./cityList";
import Container from "./UI/Container";
import Geocode from "react-geocode";

const Add = ({ addEvent }) => {
  const titleRef = useRef();
  const descriptionRef = useRef();
  const cantonRef = useRef();
  const cityRef = useRef();
  const positionRef = useRef();
  const categoryRef = useRef();
  const imageRef = useRef();
  const startdateRef = useRef();
  const enddateRef = useRef();
  const priceRef = useRef();
  const nameRef = useRef();
  const mailRef = useRef();
  const phoneRef = useRef();
  const siteUrlRef = useRef();
  const bookingUrlRef = useRef();

  const [city, setCity] = useState();
  const [canton, setCanton] = useState();
  const [category, setCategory] = useState();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    titleRef.current.focus();
  }, []);

  init("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    ///////////////////
    // Upload image //
    /////////////////
    // Create formData object to send files
    let formData = new FormData();
    let image = imageRef.current;

    let imageFile = image.files[0];
    if (imageFile) {
      formData.append("file", imageFile);
      formData.append("upload_preset", process.env.REACT_APP_CLOUD_KEY);

      // Post file on /upload
      const response = await Axios.post(
        "https://api.cloudinary.com/v1_1/dudkqb0ds/image/upload",
        formData
      );
      if (response) {
        const secureUrl = response.data.secure_url;

        /////////////////////////////////////////////
        // Get latitude & longitude from address. //
        ////////////////////////////////////////////
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
        Geocode.setLanguage("fr");
        Geocode.setRegion("ch");

        const address = positionRef.current.value;

        if (address) {
          Geocode.fromAddress(address).then(
            (response) => {
              const { lat, lng } = response.results[0].geometry.location;
              const obj = {
                lat: lat,
                lng: lng,
              };

              //////////////////////////////
              // Create new Event object //
              ////////////////////////////
              const newEvent = {
                title: titleRef.current.value,
                description: descriptionRef.current.value,
                location: cityRef.current.value,
                position: obj,
                category: categoryRef.current.value,
                image: secureUrl,
                startdate: startdateRef.current.value,
                enddate: enddateRef.current.value,
                price: priceRef.current.value,
                publish: false,
                name: nameRef.current.value,
                mail: mailRef.current.value,
                phone: phoneRef.current.value,
                siteUrl: siteUrlRef.current.value,
                bookingUrl: bookingUrlRef.current.value,
              };
              

              ///////////////////////////////////
              ///////// SEND MAIL ///////////////
              ///////////////////////////////////
              if (newEvent) {
                emailjs
                  .send(
                    process.env.REACT_APP_SERVICE_MAIL,
                    process.env.REACT_APP_TEMPLATE_MAIL_EVENT_CREATED,
                    newEvent,
                    process.env.REACT_APP_USER_MAIL
                  )
                  .then(
                    (result) => {
                      setLoading(false);
                      setErrorMessage(
                        "Merci! Votre publication sera validée sous 24h!"
                      );
                      console.log(result.text);
                    },
                    (error) => {
                      console.log(error.text);
                    }
                  );
              } else {
                setErrorMessage("Merci de remplir tous les champs !");
              }

              addEvent(newEvent);
              setTimeout(() => {
                navigate("/events");
              }, 8000);
            },
            (error) => {
              console.error(error);
              console.log('Address not found');
              setErrorMessage("Address not found, please enter a real address !");
            }
          );
        }
      }
    }
  };

  return (
    <Container>
      <Helmet>
        <title>
          Publier & Ajouter un événement en Suisse Romande | alaffiche.ch
        </title>
        <meta
          name="description"
          content="Remplissez le formulaire pour publier et ajouter un événement en Suisse Romande sur alaffiche.ch "
        />
      </Helmet>
      <h1 className="text-3xl">Publier</h1>

      <form onSubmit={handleSubmit}>
        <div className="w-full sm:flex flex-col sm:w-5/12 m-auto">
          <input
            required
            ref={titleRef}
            className="w-full mt-10 border rounded-md h-8 pl-3 text-black"
            type="text"
            placeholder="Titre"
            name="title"
          />

          <textarea
            required
            ref={descriptionRef}
            className="w-full mt-10 border rounded-md h-32 pl-3"
            type="text"
            placeholder="Déscription"
            name="description"
          />

          <section className="flex justify-between">
            <select
              required
              ref={cantonRef}
              value={canton}
              className="w-4/12 mt-10 border rounded-md h-8 pl-3 text-gray-600"
              onChange={(e) => {
                setCanton(e.target.value);
              }}
            >
              <option default value="">
                Canton
              </option>
              <option value="Genève">Genève</option>
              <option value="Vaud">Vaud</option>
              <option value="Valais">Valais</option>
              <option value="Fribourg">Fribourg</option>
              <option value="Neuchâtel">Neuchâtel</option>
              <option value="Jura">Jura</option>
            </select>

            <select
              required
              ref={cityRef}
              value={city}
              className="w-7/12 mt-10 border rounded-md h-8 pl-3 text-gray-600"
              onChange={(e) => {
                setCity(e.target.value);
              }}
            >
              <option default value="">
                Ville
              </option>
              {cityList[canton]?.map((city) => {
                return (
                  <option key={city} value={city}>
                    {city}
                  </option>
                );
              })}
            </select>
          </section>

          <input
            required
            ref={positionRef}
            className="w-full mt-10 border rounded-md h-8 pl-3"
            type="text"
            placeholder="Adresse"
            name="position"
          />

          <select
            required
            ref={categoryRef}
            value={category}
            className="w-full mt-10 border rounded-md h-8 pl-3 text-gray-600"
            onChange={(e) => {
              setCategory(e.target.value);
            }}
          >
            <option default value="">
              Catégorie
            </option>
            <option value="Evènement">Evènement</option>
            <option value="Activité">Activité</option>
            <option value="Concert">Concert</option>
            <option value="Spéctacle">Spéctacle</option>
            <option value="Film">Film</option>
            <option value="Sport">Rencontre Sportive</option>
          </select>

          <input
            required
            ref={imageRef}
            className="w-full mt-10 border rounded-md h-8 pl-3 text-gray-600"
            type="file"
            name="image"
            id="image"
            accept=".png, .jpg, .jpeg"
          />

          <input
            required
            ref={startdateRef}
            className="w-full mt-10 border rounded-md h-8 pl-3 text-gray-600"
            type="datetime-local"
            name="startdate"
            id="startdate"
          />

          <input
            required
            ref={enddateRef}
            className="w-full mt-10 border rounded-md h-8 pl-3 text-gray-600"
            type="datetime-local"
            name="enddate"
            id="enddate"
          />

          <input
            ref={priceRef}
            className="w-full mt-10 border rounded-md h-8 pl-3"
            type="number"
            placeholder="Prix"
            name="price"
          />

          <hr className="mt-10  border-orange-400"></hr>

          <input
            required
            ref={nameRef}
            className="w-full mt-10 border rounded-md h-8 pl-3 text-black"
            type="text"
            placeholder="Nom"
            name="name"
          />

          <input
            required
            ref={mailRef}
            className="w-full mt-10 border rounded-md h-8 pl-3 text-black"
            type="email"
            placeholder="E-Mail"
            name="mail"
          />

          <input
            required
            ref={phoneRef}
            className="w-full mt-10 border rounded-md h-8 pl-3 text-black"
            type="number"
            placeholder="Téléphone"
            name="phone"
          />

          <input
            ref={siteUrlRef}
            className="w-full mt-10 border rounded-md h-8 pl-3 text-black"
            type="url"
            placeholder="Site web"
            name="siteUrl"
          />

          <input
            ref={bookingUrlRef}
            className="w-full mt-10 border rounded-md h-8 pl-3 text-black"
            type="url"
            placeholder="Lien de Reservation"
            name="bookingUrl"
          />

          <button
            type="submit"
            className="w-full mt-10 border rounded-lg h-12 pl-3 bg-orange-500 text-white text-lg hover:bg-orange-400 font-semibold"
          >
            {loading ? (
              <span>
                <svg
                  role="status"
                  className="inline mr-3 w-4 h-4 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                uploading{" "}
              </span>
            ) : (
              <span>Envoyer</span>
            )}
          </button>
          <p className="text-lg text-orange-600 mt-5">{errorMessage}</p>
        </div>
      </form>
    </Container>
  );
};

export default Add;
