import React from "react";

const Paginate = (props) => {
  //console.log("props =>", props);
  const { filteredEvents, eventPerPage, changePage } = props;

  const nbrOfPage = Math.ceil(filteredEvents / eventPerPage);
  // console.log(
  //   "🚀 ~ file: paginate.jsx ~ line 8 ~ Paginate ~ nbrOfPage",
  //   nbrOfPage
  // );

  const pagesNbr = [];
  for (let index = 1; index <= nbrOfPage; index++) {
    pagesNbr.push(index);
  }

  return (
    <nav>
      <ul className="flex justify-center mt-10">
        {pagesNbr.map((index) => {
          return (
            <li
              key={index}
              onClick={() => {
                changePage(index);
              }}
              className="mr-5 border px-4 py-2 rounded-full cursor-pointer hover:bg-orange-400 hover:text-white"
            >
              {index}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Paginate;
