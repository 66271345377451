import React, { useState } from "react";
import { Helmet } from 'react-helmet-async';
import Card from "./UI/Card";
import Container from "./UI/Container";
import Paginate from "./UI/Paginate";

const Events = ({ listEvents }) => {
  //console.log("listEvents in events=> ", listEvents);

  const [currentPage, setCurrentPage] = useState(1);
  const [eventByPage] = useState(16);

  const filteredEvents = listEvents.filter((event) => {
    return event.publish && !event.archived;
  });

  // Pagination logic
  const lastIndex = currentPage * eventByPage;
  const firstIndex = lastIndex - eventByPage;
  const paginateEvents = filteredEvents.slice(firstIndex, lastIndex);

  const changePage = (index) => {
    setCurrentPage(index);
    window.scrollTo(0, 0)
    //console.log("setCurrentPge to ", index);
  };

  return (
    <Container>
      <Helmet>
        <title>Evénements, activitées & sorties en Suisse Romande | alaffiche.ch</title>
        <meta
          name="description"
          content="Liste de tous les événements, concerts, spectacles, films, activitées et sorties en Suisse Romande "
        />
        
      </Helmet>
      <div className=" w-full flex justify-around flex-wrap mt-4 min-h-screen">
        {paginateEvents.map((event) => {
          return <Card key={event._id} event={event} />;
        })}
      </div>
      <Paginate
        filteredEvents={filteredEvents.length}
        eventPerPage={eventByPage}
        changePage={changePage}
      />
    </Container>
  );
};

export default Events;
