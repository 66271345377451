import React from "react";
import Card from "./UI/Card";
import { useState } from "react";
import { useEffect } from "react";
import Container from "./UI/Container";
import { MdStarRate } from "react-icons/md";

const Featured = ({ listEvents, cat }) => {
  const [listFeatured, setListFeatured] = useState([]);

  useEffect(() => {
    const featured = listEvents.filter((event) => {
      return event.featured && event.category === cat && event.publish && !event.archived;
    });
    setListFeatured(featured);
  }, [listEvents]);

  return (
    <>
      <Container>
        {listFeatured.length > 0 && (
          
          <>
            <h2 className="text-sm sm:text-lg md:text-xl mb-0 text-gray-600 font-semibold uppercase text-left md:pl-3">
              <span className="relative text-3xl top-9 mr-8 text-yellow-500">
                <MdStarRate />
              </span>
              {`${cat}s a la une en Romandie`}
            </h2>
            <div className="w-full flex justify-around flex-wrap  pb-4 pt-0 md:pt-5 ">
              {listFeatured.map((event, id) => {
                return id < 5 ? <Card key={event._id} event={event} featured={true} /> : null
           
              })}
            </div>
            <hr />
          </>
        )}
      </Container>
    </>
  );
};

export default Featured;
