import React from "react";
import { Link } from "react-router-dom";
import top from '../../images/top.png';
//import {Image} from 'cloudinary-react';


const Card = (props) => {
  const { title, image, location, featured, archived, category, _id } = props.event;

  const seoUrl = (category  + "/" + title + "/" + location + "/" + _id)
    .toLowerCase()
    .replaceAll(" ", "-")
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replaceAll("'", "-");

  
  
  return (
    <div className={!archived ? "cursor-pointer w-1/2 px-1 pt-2 mb-1  md:w-4/12 lg:w-1/5  md:mx-1 hover:text-orange-400 relative" : "cursor-pointer w-1/2 px-1 pt-2 mb-5  md:w-4/12 lg:w-1/5  md:mx-1  grayscale hover:text-gray-600 relative opacity-80"}>
      {featured && <img className="absolute top-1 right-3 w-3/12" src={top} alt="Top" />}
      <Link to={!archived ? `/events/${seoUrl}` : `/events/${seoUrl}`} state={{ event: props.event }}>
        <img
          src={image}
          alt={`Affiche Poster ${category} ${title} ${location}`}
          className="cursor-pointe h-auto  m-auto rounded-xl"
        />
        {/* <Image  cloudName={process.env.REACT_APP_CLOUD_NAME} publicId={image} alt={title} className="cursor-pointe max-h-full m-auto rounded-md r"/> */}
        <h2 className="cursor-pointer text-sm text-left lg:text-md mt-2 ">{title}</h2>
        <p className="text-gray-400 text-xs text-left  ">{location}</p>
      </Link>
    </div>
  );
};

export default Card;

