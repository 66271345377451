import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  MdMyLocation,
  MdEvent,
  MdOutlineSportsVolleyball,
  MdLocalMovies,
  MdAccessTime,
  MdDateRange,
  MdOutlinePhonelinkRing,
  MdOutlineAlternateEmail,
  MdOutlineShoppingBag
} from "react-icons/md";
import {HiExternalLink} from 'react-icons/hi'
import { FaWalking, FaLink } from "react-icons/fa";
import { IoTicketSharp } from "react-icons/io5";
import Map from "./UI/Map";
import Container from "./UI/Container";
import top from "../images/top-lg.png";
import API from "../API/api";
import {Helmet} from "react-helmet-async";

const Event = () => {
  const [event, setEvent] = useState({});
  
  const location = useLocation();
  
  useEffect(() => {
    if (location.state) {
      setEvent(location.state.event);
    } else {
      const getEvent = async () => {
        const res =  await API.get(location.pathname);
        setEvent(res.data);
      };
      getEvent();
    }
  }, []);

  window.scroll(0, 0);

  const title = event ? event.category + " " + event.title + " " + event.location : "alaffiche.ch | Evènements, festivals, foires, activités, manifestations, concerts, spéctacle, théatre, cinema à l'affiche en Suisse romande" ;

  return (
    <Container>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={event.description ? event.description.slice(0, 180) + '...' : "Tous les Evènements, festivals, foires, activités, films, concerts, à l'affiche en Suisse romande"}
        />
      </Helmet>
      <div className=" w-full md:flex md:mt-6 lg:mt-0 justify-between">
        <div className=" md:w-2/5 relative mb-4">
          <img
            className={
              !event.archived
                ? "rounded-lg m-auto "
                : "rounded-lg m-auto grayscale"
            }
            src={event.image}
            alt={'Affiche Poster ' + title}
          />
          {event.featured && (
            <img
              className="absolute top-5 right-7 w-16 md:w-18 "
              src={top}
              alt="Top"
            />
          )}
        </div>

        <div className=" md:w-3/5 pt-1 md:pt-0 md:pl-10 text-left">
          <h1 className="text-3xl">{event.title}</h1>
          {event.archived && <p className="text-gray-500 mt-2">Archive</p>}
          <div className="mt-3 text-orange-400 font-bold flex align-middle relative bottom-1 flex justify-between">
            <div className="flex">
              <span className="relative top-1">
                <MdMyLocation />{" "}
              </span>
              <span className="ml-1 ">{event.location}</span>
            </div>
            <div className="flex">
              <span className="relative top-1">
                {event.category === "Evènement" && <MdEvent />}
                {event.category === "Concert" && <IoTicketSharp />}
                {event.category === "Activité" && <FaWalking />}
                {event.category === "Spéctacle" && <MdLocalMovies />}
                {event.category === "Film" && <MdLocalMovies />}
                {event.category === "Rencontre Sportive" && (
                  <MdOutlineSportsVolleyball />
                )}
              </span>
              <span className="ml-1 ">{event.category}</span>
            </div>
          </div>
          <p className="mt-5 md:mr-3 text-gray-500 text-justify">
            {event.description}
          </p>
          <hr className="mt-8" />
          <h2 className="text-2xl pt-7">Dates & Horaires</h2>
          <div className="mt-5 md:mr-3 text-gray-500 flex justify-between">
            <div>
              <span className="flex">
                <MdDateRange className="relative top-1 mr-2" />
                <span className="hidden sm:block font-bold mr-2">
                  {" "}
                  Date de Debut:
                </span>{" "}
                <span className="text-orange-400 font-semibold">
                  {new Date(event.startdate).toLocaleDateString()}
                </span>{" "}
              </span>
            </div>
            <div>
              <span className="flex">
                <MdAccessTime className="relative top-1 mr-2" />
                <span className="hidden sm:block font-bold mr-2">
                  Heure:
                </span>{" "}
                <span className="text-orange-400 font-semibold">
                  {new Date(event.startdate).getUTCHours()}h{new Date(event.startdate).getUTCMinutes() < 10 ? '0' + new Date(event.startdate).getUTCMinutes() : new Date(event.startdate).getUTCMinutes()}
                </span>
              </span>
            </div>
          </div>
          <div className="mt-5 md:mr-3 text-gray-500 flex justify-between">
            <div>
              <span className="flex">
                <MdDateRange className="relative top-1 mr-2" />
                <span className="hidden sm:block font-bold mr-2">
                  Date de Fin:
                </span>{" "}
                <span className="text-orange-400 font-semibold">
                  {new Date(event.enddate).toLocaleDateString()}
                </span>{" "}
              </span>
            </div>
            <div>
              <span className="flex">
                <MdAccessTime className="relative top-1 mr-2" />
                <span className="hidden sm:block font-bold mr-2">
                  Heure:
                </span>{" "}
                <span className="text-orange-400 font-semibold">
                {new Date(event.enddate).getUTCHours()}h{new Date(event.enddate).getUTCMinutes() < 10 ? '0' + new Date(event.enddate).getUTCMinutes() : new Date(event.enddate).getUTCMinutes()}
                </span>
              </span>
            </div>
          </div>
          <hr className="mt-8" />
          <h2 className="text-2xl pt-7">Emplacement & Itineraires</h2>
          <div className="w-full rounded-md bg-slate-200 h-64 mt-5">
            {event.position && (
              <Map
                position={{
                  lat: Number(event.position.lat),
                  lng: Number(event.position.lng),
                }}
              />
            )}
          </div>
          <hr className="mt-10" />
          <h2 className="text-2xl pt-7">Tarifs & Details</h2>
          <p className="text-orange-400 text-xl mt-4 font-bold">
            {event.price ? event.price + ".- CHF" : "Gratuit"}
          </p>


          <hr className="mt-8" />
          
          <h2 className="text-2xl pt-7">Organisation & Contact</h2>
          <div className="mt-5 md:mr-3 text-gray-500 flex justify-between">
            <div>
              <span className="flex">
                <MdOutlinePhonelinkRing className="relative top-1 mr-2 text-orange-400" />
                <span className={` sm:block font-bold mr-2  ${false ? "cursor-pointer hover:text-orange-400 " : "cursor-not-allowed"}`}>
                  {" "}
                  Téléphone
                </span>{" "}
                
              </span>
            </div>
            <div>
              <span className="flex">
                <FaLink className="relative top-1 mr-2 text-orange-400" />
                <span className={` sm:block font-bold mr-2  ${false ? "cursor-pointer hover:text-orange-400 " : "cursor-not-allowed"}`}>
                  Site Web
                </span>{" "}
                
              </span>
            </div>
          </div>
          <div className="mt-5 md:mr-3 text-gray-500 flex justify-between">
            <div>
              <span className="flex">
                <MdOutlineAlternateEmail className="relative top-1 mr-2 text-orange-400" />
                <span className={` sm:block font-bold mr-2  ${false ? "cursor-pointer hover:text-orange-400 " : "cursor-not-allowed"}`}>
                  Mail
                </span>{" "}
               
              </span>
            </div>
            <div>
              <span className="flex">
                <MdOutlineShoppingBag className="relative top-1 mr-2 text-orange-400" />
                <span className={` sm:block font-bold mr-2  ${false ? "cursor-pointer hover:text-orange-400 " : "cursor-not-allowed"}`}>
                  Résérvation
                </span>{" "}
               
              </span>
            </div>
          </div>

        </div>
      </div>
    </Container>
  );
};

export default Event;
