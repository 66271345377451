const cityList = {
  Vaud: [
    "Aigle",
    "Aigle District",
    "Apples",
    "Arzier",
    "Aubonne",
    "Avenches",
    "Ballens",
    "Bavois",
    "Begnins",
    "Belmont-sur-Lausanne",
    "Berolle",
    "Bex",
    "Bière",
    "Blécherette",
    "Blonay",
    "Bottens",
    "Broye-Vully District",
    "Bussigny",
    "Caux",
    "Chardonne",
    "Château-d'Oex",
    "Chavannes",
    "Chavannes-le-Veyron",
    "Chavornay",
    "Cheseaux",
    "Chevilly",
    "Chexbres",
    "Clarens",
    "Colombier",
    "Coppet",
    "Cossonay",
    "Crissier",
    "Cuarnens",
    "Cully",
    "Echallens",
    "Ecublens",
    "Epalinges",
    "Estavayer-Le-Lac",
    "Ferreyres",
    "Founex",
    "Froideville",
    "Genolier",
    "Gimel",
    "Gland",
    "Grancy",
    "Grandson",
    "Gros-de-Vaud District",
    "Gryon",
    "Jongny",
    "Jura-Nord vaudois District",
    "La Sarraz",
    "La Tour-de-Peilz",
    "Lausanne",
    "Lausanne District",
    "Lavaux-Oron District",
    "Le Chenit",
    "Le Mont-sur-Lausanne",
    "Le Vaud",
    "Leysin",
    "Lucens",
    "Lutry",
    "Mauraz",
    "Mollens",
    "Montagny",
    "Montreux",
    "Monthey",
    "Morges",
    "Morges District",
    "Moudon",
    "Nyon",
    "Nyon District",
    "Ollon",
    "Orbe",
    "Oron-la-Ville",
    "Ouest Lausannois District",
    "Pailly",
    "Palézieux",
    "Pampigny",
    "Paudex",
    "Payerne",
    "Penthalaz",
    "Penthéréaz",
    "Perroy",
    "Pompaples",
    "Prangins",
    "Préverenges",
    "Prilly",
    "Puidoux",
    "Pully",
    "Renens",
    "Riviera-Pays-d'Enhaut District",
    "Rolle",
    "Ropraz",
    "Romanel-sur-Lausanne",
    "Rougemont",
    "Saint-Cergue",
    "Saint-Livres",
    "Saint-Prex",
    "Sainte-Croix",
    "Saubraz",
    "Savigny",
    "Sottens",
    "Trélex",
    "Vallorbe",
    "Vevey",
    "Villars-sur-Ollon",
    "Villeneuve",
    "Vuarrens",
    "Yverdon-les-Bains",
    "Yvonand",
  ],
  Fribourg: [
    "Attalens",
    "Avry-sur-Matran",
    "Bas-Vully",
    "Belfaux",
    "Broc",
    "Broye District",
    "Bulle",
    "Charmey",
    "Châtel-Saint-Denis",
    "Corminboeuf",
    "Courgevaux",
    "Cugy",
    "Domdidier",
    "Ecublens",
    "Ependes",
    "Estavayer-le-Lac",
    "Flamatt",
    "Fribourg",
    "Giffers",
    "Givisiez",
    "Glâne District",
    "Grolley",
    "Gruyère District",
    "Gruyères",
    "La Roche",
    "La Tour-de-Trême",
    "Lake District",
    "Marly",
    "Marsens",
    "Mézières",
    "Morat",
    "Plaffeien",
    "Praroman",
    "Riaz",
    "Romont",
    "Rue",
    "Sâles",
    "Sankt Antoni",
    "Sarine District",
    "Sense District",
    "Treyvaux",
    "Veveyse District",
    "Villars-sur-Glâne",
    "Villaz-Saint-Pierre",
    "Vuadens",
  ],
  Genève: [
    "Aire-la-Ville",
    "Anières",
    "Bellevue",
    "Bernex",
    "Carouge",
    "Chancy",
    "Chêne-Bougeries",
    "Chêne-Bourg",
    "Confignon",
    "Corsier",
    "Dardagny",
    "Genève",
    "Jussy",
    "Lancy",
    "Le Grand-Saconnex",
    "Les Avanchets",
    "Meinier",
    "Meyrin",
    "Onex",
    "Plan-les-Ouates",
    "Puplinge",
    "Satigny",
    "Thônex",
    "Troinex",
    "Vandœuvres",
    "Vernier",
    "Versoix",
    "Veyrier",
  ],
  Valais: [
    "Ardon",
    "Ayent",
    "Bagnes",
    "Basse-Nendaz",
    "Brig",
    "Brig District",
    "Chalais",
    "Chamoson",
    "Champéry",
    "Charrat",
    "Chermignon-d’en Haut",
    "Chippis",
    "Collombey",
    "Conthey",
    "Conthey District",
    "Entremont District",
    "Evionnaz",
    "Evolène",
    "Fully",
    "Gampel",
    "Goms District",
    "Grimisuat",
    "Grône",
    "Hérémence",
    "Hérens District",
    "Lens",
    "Leuk",
    "Leuk District",
    "Leytron",
    "Martigny District",
    "Martigny-Combe",
    "Martigny",
    "Montana",
    "Monthey",
    "Monthey District",
    "Naters",
    "Orsières",
    "Randogne",
    "Raron",
    "Raron District",
    "Riddes",
    "Saas-Fee",
    "Saas-Grund",
    "Saillon",
    "Saint-Léonard",
    "Saint-Maurice",
    "Saint-Maurice District",
    "Salvan",
    "Savièse",
    "Saxon",
    "Sierre",
    "Sierre District",
    "Sion",
    "Sion District",
    "Sitten",
    "Tanay",
    "Troistorrents",
    "Varen",
    "Verbier",
    "Vernayaz",
    "Vétroz",
    "Vex",
    "Vionnaz",
    "Visp",
    "Visp District",
    "Visperterminen",
    "Vouvry",
    "Zermatt",
  ],
  Neuchâtel: [
    "Auvernier",
    "Bevaix",
    "Boudry",
    "Boudry District",
    "Cernier",
    "Chézard-Saint-Martin",
    "Cornaux",
    "Cortaillod",
    "Couvet",
    "Dombresson",
    "Fleurier",
    "Fontainemelon",
    "Gorgier",
    "Grand-Savagnier",
    "La Chaux-de-Fonds",
    "La Chaux-de-Fonds District",
    "Le Landeron",
    "Le Locle",
    "Le Locle District",
    "Les Brenets",
    "Les Geneveys-sur-Coffrane",
    "Les Ponts-de-Martel",
    "Marin-Epagnier",
    "Neuchâtel",
    "Neuchâtel District",
    "Peseux",
    "Saint-Aubin-Sauges",
    "Saint-Blaise",
    "Travers",
    "Val-de-Ruz District",
    "Val-de-Travers District",
  ],
  Jura: [
    "Alle",
    "Bassecourt",
    "Boncourt",
    "Courfaivre",
    "Courgenay",
    "Courrendlin",
    "Courroux",
    "Courtételle",
    "Delémont",
    "Delémont District",
    "Fontenais",
    "Franches-Montagnes District",
    "Glovelier",
    "Le Noirmont",
    "Les Bois",
    "Les Breuleux",
    "Porrentruy",
    "Porrentruy District",
    "Saignelégier",
    "Vicques",
  ],
};

export default cityList;
