import "./App.css";
import Header from "./components/Header";
import Search from "./components/Search";
import Events from "./components/Events";
import Featured from "./components/Featured";
import Event from "./components/Event";
import About from "./components/About";
import Footer from "./components/Footer";
import { useEffect, useState } from "react";
import API from "./API/api";
import { Routes, Route , Link} from "react-router-dom";
import Add from "./components/Add";
import Archive from "./components/Archive";
import Container from "./components/UI/Container";
import Spiner from "./components/UI/Spiner";
import banner from "./images/west_side_story_banner.png";
import {Helmet} from "react-helmet-async";

const App = () => {
  console.log("#### STARTING FULL STACK APP ######");

  const [loading, setLoading] = useState(false);
  const [listEvents, setListEvents] = useState([]);

  let data = JSON.parse(localStorage.getItem("listEvents"));

  ////////////////////////////////////////
  ///// Rmv localStorage after TTL //////
  //////////////////////////////////////

  const TTL = JSON.parse(localStorage.getItem("TTL"));
  const timeNow = new Date().getTime();
  if (timeNow > TTL) {
    localStorage.removeItem("listEvents");
    localStorage.removeItem("TTL");
  }

  ////////////////////////
  ///// Get Events //////
  //////////////////////

  useEffect(() => {
    if (!data) {
      //console.log("///////////// API CAll ////////////////");
      setLoading(true);
      const getEventData = async () => {
        const response = await API.get("/events");
        const eventsData = response.data;
        if (eventsData) {
          /////////////////////////////////////////////
          ///// Set archived for expired event ///////
          ///////////////////////////////////////////

          const today = new Date().getTime();
          const listEventWithArchiveSet = eventsData.map((event) => {
            const dateEndEvent = new Date(event.enddate).getTime();
            // set archived to true or false before returning event
            const eventArchived = { ...event, archived: true };
            const eventNotArchived = { ...event, archived: false };
            if (dateEndEvent < today) {
              return eventArchived;
            } else {
              return eventNotArchived;
            }
          });

          /////////////////////////////////
          // Reverse order and setState //
          ///////////////////////////////

          const finalEventsList = listEventWithArchiveSet.reverse();
          setListEvents(finalEventsList);
          localStorage.setItem("listEvents", JSON.stringify(finalEventsList));
          localStorage.setItem(
            "TTL",
            JSON.stringify(new Date().getTime() + 7400000)
          ); // set TTL to 2h00
          setLoading(false);
        } else {
          setLoading(true);
          setListEvents([]);
        }
      };
      getEventData();
    } else {
      //console.log("|||||||||||||||| LOCAL STORAGE CAll ||||||||||||||||||");
      setListEvents(data);
    }
  }, []);

  ////////////////////////
  ///// Add Event ///////
  //////////////////////

  const addEvent = async (event) => {
    // Create event with post on /events in DB
    // console.warn('Get in addEvent fn with, ', event);
    const eventCreated = await API.post("/events", event);
    const eventData = eventCreated.data;
    if (eventData) {
      const newListEvents = [...listEvents, eventData];
      setListEvents(newListEvents);
      localStorage.setItem("listEvents", JSON.stringify(newListEvents));
      return eventData;
    } else {
     
    }
  };

  return (
    <div className="App">
      <Helmet>
        <title>Evènements, festivals, foires, activités, manifestations,
      concerts, spéctacle, théatre, cinema à l'affiche en Suisse romande | alaffiche.ch</title>
      </Helmet>
      <Header />

      <Routes>
        <Route
          path="/"
          //element={loading ? <Spiner /> : <Events listEvents={listEvents} />}
          element={
            <Container>
              {loading ? (
                <Spiner />
              ) : (
                <>
                  <h1 className="text-lg sm:text-xl md:text-2xl  mb-2 md:mb-3 text-gray-600 font-semibold uppercase ">A l'affiche en Suisse romande </h1>
                  <Link to="/events/spectacle/west-side-story/lausanne/63692dbfa0bf934f3c2fad4d">
                  <img src={banner} className="rounded-xl" />
                  </Link>
                  <Featured listEvents={listEvents} cat="Evènement" />
                  <Featured listEvents={listEvents} cat="Concert" />
                  <Featured listEvents={listEvents} cat="Spéctacle" />
                  <Featured listEvents={listEvents} cat="Activité" />
                  {/* <HomeSection listEvents={listEvents} category="Film"/> */}
                </>
              )}
            </Container>
          }
        />
        <Route
          path="/events"
          element={loading ? <Spiner /> : <Events listEvents={listEvents} />}
        />

        <Route path="/events/:category/:city/:title/:id" element={<Event />} />

        <Route
          path="/search"
          element={loading ? <Spiner /> : <Search listEvents={listEvents} />}
        />

        <Route
          path="/archive"
          element={loading ? <Spiner /> : <Archive listEvents={listEvents} />}
        />

        <Route path="/add" element={<Add addEvent={addEvent} />} />

        <Route path="/about" element={<About />} />
      </Routes>

      <Footer />
    </div>
  );
};

export default App;
