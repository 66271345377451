import React from 'react'

const Container = (props) => {
  return (
    <div className='md:max-w-6xl md:mx-auto px-3 pt-1  md:px-2 md:mt-2'>
      {props.children}
    </div>
  )
}

export default Container
